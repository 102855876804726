/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import './redeem.scss';
import axios from 'axios';
import { BallBeat, BallClipRotate } from '../Loader/loader';
import ToastNotification from '../toast';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



// toast.configure();

class AdminSignupWithRedeem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            lastName: '',
            responseStatus1: false,
            resposneStatusText1: '',
            firstName: '',
            password: '',
            emailvalidError: false,
            passwordValidError: false,
            responseStatus: false,
            isLoading: false,
            company_name: "",
            companyNameValidError: false,
            companyNameError: "",
            searchJobs: [],
            jobs: [],
            validate: false,
            coupon: '',
            imgSelected: [],
            jobSelected: false,
            search: '',
            show1: false
        }
    }

    componentDidMount() {
        var imagesList = [
            { image: '/images/elite-login1.jpg' },
            { image: '/images/elite-login2.png' },
            { image: '/images/elite-login3.jpg' }
        ];
        let imgSelected = imagesList[Math.floor(Math.random() * imagesList.length)];
        this.setState({
            imgSelected: imgSelected
        })
        this.getJobTitles();
    }

    getJobTitles = () => {
        axios.get(process.env.REACT_APP_API_KEY + 'superadmin/onboard/jobs').then((response) => {
            this.setState({
                jobs: response.data.data
            })
        })
            .catch(function (error, response) {
                console.log(error, response);
            })
    }

    firstNameChange(e) {
        this.setState({
            firstName: e,
            firstNameError: '',
            firstNameValid: false,
            lastNameError: '',
            lastnameValid: false,
            emailError: '',
            emailvalidError: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }

    lastNameChange(e) {
        this.setState({
            lastName: e,
            firstNameError: '',
            firstNameValid: false,
            lastNameError: '',
            lastnameValid: false,
            emailError: '',
            emailvalidError: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }
    onEmailChange(e) {
        this.setState({
            emailvalidError: false,
            email: e.target.value,
            responseStatus: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }
    onChangePassword(e) {
        this.setState({
            password: e.target.value,
            passwordValidError: false,
            responseStatus: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }
    companyNameChange = (val) => {
        var regex = /^[A-Za-z0-9 ]+$/

        //Validate company name value against the Regex.
        if (val !== '' && !regex.test(val)) {
            this.setState({
                companyNameError: `Special characters not allowed in company name`,
                companyNameValidError: true
            })
        } else {
            this.setState({
                company_name: val,
                companyNameError: "",
                companyNameValidError: false,
                responseStatus: false,
                responseStatus1: false,
                resposneStatusText1: ''

            })
        }
    }

    onChangeCoupon = (val) => {
        this.setState({
            coupon: val,
            couponError: "",
            couponValidError: false,
            responseStatus: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }

    validateCoupon = (e) => {
        e.preventDefault();
        if (this.state.coupon.trim('') === '') {
            this.setState({
                couponValidError: true,
                couponError: 'Please enter Coupon code',
                isLoading: false
            });
        }
        else {
            axios.get(process.env.REACT_APP_API_KEY + `superadmin/onboard/coupon?code=${this.state.coupon}`).then((response) => {
                if (response.data.success) {
                    this.setState({
                        validate: true
                    })
                }
                else {
                    this.setState({
                        couponValidError: true,
                        isLoading: false,
                        couponError: 'Coupon code is invalid',
                        validate: false
                    })
                }
            })
                .catch(function (error, response) {
                    console.log(error, response);
                })
        }
    }

    componentWillMount() {
        this.timer = null;
    }

    onChangeJobtitle = (val) => {
        this.setState({
            jobSelected: true
        })
        clearTimeout(this.timer);
        this.setState({
            search: val,
            jobtitle: ''
        })
        this.timer1 = setTimeout(
            this.delaySearch, 800);
    }

    delaySearch = () => {
        const filtered = this.state.jobs.filter(el => {
            if (this.state.search === '') {
                return
            }
            else if (el.title.toLowerCase().includes(this.state.search.toLowerCase())) {
                return el
            }
        })
        this.setState({
            searchJobs: filtered,
            jobSelected: false,
            jobtitleError: '',
            jobtitleValidError: false
        })
    }

    selectedJob = (job) => {
        console.log(job)
        this.setState({
            search: job.title,
            jobtitle: job._id,
            searchJobs: [],
            jobtitleError: '',
            jobtitleValidError: false
        })
    }

    loginOnKeyPress(e) {
        if (e.which === 13 || e.keyCode === 13) {
            this.setState({
                isLoading: true
            })
            let emailvalue = this.state.email;
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.state.company_name === "") {
                this.setState({
                    companyNameValidError: true,
                    companyNameError: 'Please enter Company name',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': 'Please enter Company name',
                    'Error URL': window.location.href
                });
            }


            else if (!this.state.firstName) {
                this.setState({
                    firstNameError: "Please enter the first name",
                    firstNameValid: true,
                    isLoading: false
                })
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.firstNameError,
                    'Error URL': window.location.href
                });
                return false;
            }

            else if (!this.state.lastName) {
                this.setState({
                    lastNameError: 'Please enter the last name',
                    lastnameValid: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.lastNameError,
                    'Error URL': window.location.href
                });
                return false;
            }

            else if (!emailvalue) {
                this.setState({
                    emailError: 'Email cant be blank',
                    emailvalidError: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.emailError,
                    'Error URL': window.location.href
                });
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                this.setState({
                    // emailvalidError: 'Email is not valid',
                    emailError: "Email is not valid",
                    emailvalidError: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.emailError,
                    'Error URL': window.location.href
                });
                return false;
            }
            else if (this.state.password === "") {
                this.setState({
                    passwordValidError: true,
                    passworderror: 'Please enter password',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.passworderror,
                    'Error URL': window.location.href
                });
            }
            else if (this.state.search === '') {
                this.setState({
                    jobtitleValidError: true,
                    jobtitleError: 'Please Select from the suggested list of Job titles only',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.jobtitleError,
                    'Error URL': window.location.href
                });
            }
            else if (this.state.jobtitle === "") {
                this.setState({
                    jobtitleValidError: true,
                    jobtitleError: 'Please Select from the suggested list of Job titles only',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.jobtitleError,
                    'Error URL': window.location.href
                });
            }
            else if (!this.state.coupon) {
                this.setState({
                    couponvalidError: true,
                    couponError: 'Please enter coupon code',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': 'Please enter coupon code',
                    'Error URL': window.location.href
                });
            }
            else {
                var data = new FormData()
                data.append("email", this.state.email)
                data.append("password", this.state.password)
                data.append("firstName", this.state.firstName)
                data.append("lastName", this.state.lastName)
                data.append("current_job", this.state.jobtitle)
                data.append("company_name", this.state.company_name)
                data.append("code", this.state.coupon)

                if (this.state.company_logo.size > 2097152) {
                    this.setState({
                        successMessageText: "The file size cannot exceed 2mb",

                    })
                }
                axios.post(process.env.REACT_APP_API_KEY + 'superadmin/onboard/company', data).then((response) => {
                    if (response.data.success) {
                        window.location.href = response.data.url;
                    }
                    else {
                        this.setState({
                            responseStatus1: true,
                            isLoading: false,
                            resposneStatusText1: response.data.message,
                            show1: true
                        })
                    }
                })
                    .catch(function (error, response) {
                        console.log(error, response);
                    })

            }
        }
    }

    loginUserWithCoupon(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        let emailvalue = this.state.email;
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.company_name === "") {
            this.setState({
                companyNameValidError: true,
                companyNameError: 'Please enter Company name',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': 'Please enter Company name',
                'Error URL': window.location.href
            });
        }


        else if (!this.state.firstName) {
            this.setState({
                firstNameError: "Please enter the first name",
                firstNameValid: true,
                isLoading: false
            })
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.firstNameError,
                'Error URL': window.location.href
            });
            return false;
        }

        else if (!this.state.lastName) {
            this.setState({
                lastNameError: 'Please enter the last name',
                lastnameValid: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.lastNameError,
                'Error URL': window.location.href
            });
            return false;
        }

        else if (!emailvalue) {
            this.setState({
                emailError: 'Email cant be blank',
                emailvalidError: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.emailError,
                'Error URL': window.location.href
            });
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            this.setState({
                emailError: "Email is not valid",
                emailvalidError: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.emailError,
                'Error URL': window.location.href
            });
            return false;
        }
        else if (this.state.password === "") {
            this.setState({
                passwordValidError: true,
                passworderror: 'Please enter password',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.passworderror,
                'Error URL': window.location.href
            });
        }
        else if (this.state.jobtitle === "") {
            this.setState({
                jobtitleValidError: true,
                jobtitleError: 'Please Select from the suggested list of Job titles only',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.jobtitleError,
                'Error URL': window.location.href
            });
        }
        else if (this.state.search === '') {
            this.setState({
                jobtitleValidError: true,
                jobtitleError: 'Please Select from the suggested list of Job titles only',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.jobtitleError,
                'Error URL': window.location.href
            });
        }
        else if (!this.state.coupon) {
            this.setState({
                couponvalidError: true,
                couponError: 'Please enter coupon code',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': 'Please enter coupon code',
                'Error URL': window.location.href
            });
        }
        else {
            var data = new FormData()
            data.append("email", this.state.email)
            data.append("password", this.state.password)
            data.append("firstName", this.state.firstName)
            data.append("lastName", this.state.lastName)
            data.append("current_job", this.state.jobtitle)
            data.append("company_name", this.state.company_name)
            data.append("code", this.state.coupon)
            // console.log(this.state.email,this.state.password,this.state.firstName,this.state.lastName,this.state.jobtitle,this.state.company_name)
            axios.post(process.env.REACT_APP_API_KEY + 'superadmin/onboard/company', data).then((response) => {
                if (response.data.success) {
                    window.location.href = response.data.url
                    this.setState({
                        validate: false
                    })
                }
                else {
                    this.setState({
                        responseStatus1: true,
                        isLoading: false,
                        resposneStatusText1: response.data.message,
                        show1: true
                    })
                    // toast.error(this.state.resposneStatusText1, { autoClose: 5000, })
                }
            })
                .catch(function (error, response) {
                    console.log(error, response);
                })
        }
    }

    onfocus = (e) => {
        e.preventDefault();
        e.stopPropagation()
        this.setState({
            show: true
        })
        setTimeout(() => { this.setState({ show: false }); }, 2000);
    }



    renderTooltip(props) {
        return <Tooltip {...props}>
            Start entering your job title and select from the suggested list of job titles that matches closely with your current job title
        </Tooltip>;
    }


    renderNothing(props) {
        return <div />
    }

    setClose = () => {
        this.setState({
            show1: false
        })
    }
    render() {
        const { searchJobs } = this.state
        return (
            <Fragment>
                <ToastNotification
                    message={this.state.resposneStatusText1}
                    show={this.state.show1}
                    setClose={this.setClose}
                />
                <div className='admin-signup-holder' style={{ backgroundImage: `url(${this.state.imgSelected?.image})` }}>
                    <div className='login-holder'>
                        <div className='login-box'>
                            <img src='/images/elite-big-logo.png' width="158" height="68" alt='' />
                            <p className='text-center'>Create an Elite Enterprise account and start your team's learning journey!</p>
                            <div className='small'>All (*) marked fields are mandatory</div>
                            <form name='coupon-form'>
                                <div className="form-group mt-2">
                                    {
                                        this.state.companyNameValidError ? <span className='err'>{this.state.companyNameError}</span> : null
                                    }
                                    <div className="has-search"  >
                                        <span className="form-control-feedback">
                                            <img src="/images/company-icon.svg" alt='' />
                                        </span>
                                        <input type="text" name='company name' className="form-control" value={this.state.company_name} placeholder="Company Name*" onChange={(e) => this.companyNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    {
                                        this.state.firstNameValid ? <span className='err'>{this.state.firstNameError}</span> : null
                                    }
                                    <div className="has-search"  >
                                        <span className="form-control-feedback" >
                                            <img src="/images/name-icon.svg" alt='' />
                                        </span>
                                        <input type="text" name='first name' className="form-control" id="first-name" placeholder="First Name*" value={this.state.firstName} onChange={(e) => this.firstNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    {
                                        this.state.lastnameValid ? <span className='err'>{this.state.lastNameError}</span> : null
                                    }
                                    <div className="has-search"  >
                                        <span className="form-control-feedback" >
                                            <img src="/images/name-icon.svg" alt='' />
                                        </span>
                                        <input type="text" name='last name' className="form-control" id="last-name" placeholder={"Last Name*"} value={this.state.lastName} onChange={(e) => this.lastNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    {
                                        this.state.emailvalidError ? <span className='err'>{this.state.emailError}</span> : null
                                    }
                                    <div className="has-search"  >
                                        <span className="form-control-feedback" >
                                            <img src="/images/email-icon.svg" alt='' />
                                        </span>
                                        <input type="email" name='email' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email*" value={this.state.email} onChange={(e) => this.onEmailChange(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    {
                                        this.state.passwordValidError ? <span className='err'>{this.state.passworderror}</span> : null
                                    }

                                    <div className="has-search"  >
                                        <span className="form-control-feedback" >
                                            <img src="/images/lock-icon.svg" alt='' />
                                        </span>
                                        <input type="password" name='password' className="form-control" aria-describedby="emailHelp" placeholder="Password*" onChange={(e) => this.onChangePassword(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </div>
                                </div>
                                <div className="form-group position-relative">
                                    {
                                        this.state.jobtitleValidError ? <span className='err'>{this.state.jobtitleError}</span> : null
                                    }
                                    <div className="icon-selected">
                                        {this.state.jobSelected ? (
                                            <BallClipRotate
                                                color={"#000"}
                                                loading={true}
                                            />
                                        ) : null}
                                    </div>
                                    <OverlayTrigger
                                        show={this.state.show}
                                        // trigger='click'
                                        placement={'right'}
                                        arrowProps
                                        onHide={() => this.setState({ show: false })}
                                        overlay={
                                            this.state.show ?
                                                this.renderTooltip :
                                                this.renderNothing
                                        }
                                    >
                                        <input type="text" className="form-control" autoComplete='off' name='job-title' value={this.state.search} aria-describedby="Help" placeholder="Enter your Job title*" onChange={(e) => this.onChangeJobtitle(e.target.value)} onClick={(e) => this.onfocus(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                    </OverlayTrigger>
                                    {
                                        searchJobs !== undefined &&
                                            searchJobs.length > 0 &&
                                            this.state.search !== "" ? (
                                            <div className="list-container">
                                                <ul>
                                                    {
                                                        searchJobs !== undefined &&
                                                            searchJobs.length > 0
                                                            ? searchJobs.map((items, i) => {
                                                                return (
                                                                    <li
                                                                        onClick={() =>
                                                                            this.selectedJob(items)
                                                                        }
                                                                    >
                                                                        {items.title}
                                                                    </li>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </ul>
                                            </div>
                                        ) :
                                            null
                                    }
                                </div>

                                <div className='position-relative mt-2'>
                                    {
                                        this.state.responseStatus ? <span>{this.state.resposneStatusText}</span> : null
                                    }
                                    {
                                        this.state.couponValidError ? <span className="err" style={{ color: 'red' }}>{this.state.couponError}</span> : null
                                    }
                                    <div className="form-group d-flex">
                                        <input type="text" name='coupon' className="form-control" id="coupon" aria-describedby="coupon" style={{ width: '100%' }} placeholder="Enter Coupon code*" value={this.state.coupon} onChange={(e) => this.onChangeCoupon(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                        &nbsp; <button className='button-color' disabled={this.state.validate ? true : false} style={{ width: '55%', padding: 0, borderRadius: 4, height: 'unset' }} onClick={(e) => this.validateCoupon(e)}>
                                            {this.state.validate ?
                                                <Fragment>
                                                    <img src="/images/tick1.png" width="30" height="30" alt='' /> &nbsp;Validated
                                                </Fragment>
                                                : 'Validate'
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className='form-group mb-0' >
                                    <button type='submit' className='form-control button-color' disabled={this.state.validate ? false : true} style={{ opacity: this.state.validate ? 1 : 0.5, pointerEvents: this.state.isLoading ? 'none' : 'auto', borderRadius: 2, fontWeight: 500 }} onClick={(e) => this.loginUserWithCoupon(e)} >
                                        {this.state.isLoading ? <BallBeat
                                            color={'#fff'}
                                            loading={true}
                                        /> : "SUBMIT"}</button>
                                </div>
                                <p className='mt-2 cursor-pointer'>Registered user?
                                    <a href='https://app.elitelearning.io/user-login' target='_blank' style={{ textDecoration: 'none', color: '#8b7df2' }} rel="noreferrer" > Login here</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>

        )
    }
}

export default AdminSignupWithRedeem

