import React from 'react';
import Toast from 'react-bootstrap/Toast';

export default function ToastNotification(props) {
    return (
        <div>
            <div style={{ position: 'absolute', right: 0, top: 10, zIndex: 3 }}>
                <Toast show={props.show} onClose={() => props.setClose()} delay={7000} autohide className="d-inline-block m-1" bg={'Danger'.toLowerCase()} >
                    <Toast.Body className={'Danger' && 'text-white'}>
                        {props.message}
                    </Toast.Body>
                </Toast>
            </div>
        </div >
    )
}
