/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import "./register.scss";
import axios from 'axios';
import { BallBeat, BallClipRotate } from '../Loader/loader';
import ToastNotification from '../toast';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Lottie from "react-lottie";
import EmailSent from '../../assets/email-sent.json';

class AdminSignup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            lastName: '',
            responseStatus1: false,
            show1: false,
            resposneStatusText1: '',
            firstName: '',
            password: '',
            emailvalidError: false,
            passwordValidError: false,
            responseStatus: false,
            isLoading: false,
            company_name: "",
            companyNameValidError: false,
            companyNameError: "",
            searchJobs: [],
            jobs: [],
            jobSelected: false,
            search: '',
            jobtitle: '',
            defaultScreen: false,
            verified: false,
            verify: false
        }
    }

    componentDidMount() {
        var imagesList = [
            { image: '/images/elite-login1.jpg' },
            { image: '/images/elite-login2.png' },
            { image: '/images/elite-login3.jpg' }
        ];
        let imgSelected = imagesList[Math.floor(Math.random() * imagesList.length)];
        this.setState({
            imgSelected: imgSelected
        })
        this.getJobTitles();
        if (window.location.href.includes('_id')) {
            this.setState({
                defaultScreen: true
            }, () => {
                this.getUserDetails(window.location.search.split('=')[1])
            })
        }
        else {
            this.setState({
                defaultScreen: false
            })
        }
    }

    getUserDetails = (id) => {
        axios.get(process.env.REACT_APP_API_KEY + `enterprise/external/verified-data?_id=${id}`).then((response) => {
            if (response.data.success) {
                this.setState({
                    firstName: response?.data?.data?.firstName,
                    lastName: response?.data?.data?.lastName,
                    email: response?.data?.data?.email
                })
            }
            else {
                this.setState({
                    responseStatus1: true,
                    resposneStatusText1: response.data.message,
                    show1: true
                })
            }
        })
            .catch(function (error, response) {
                console.log(error, response);
            })
    }

    getJobTitles = () => {
        axios.get(process.env.REACT_APP_API_KEY + 'superadmin/onboard/jobs').then((response) => {
            this.setState({
                jobs: response.data.data
            })
        })
            .catch(function (error, response) {
                console.log(error, response);
            })
    }

    firstNameChange(e) {
        this.setState({
            firstName: e,
            firstNameError: '',
            firstNameValid: false,
            lastNameError: '',
            lastnameValid: false,
            emailError: '',
            emailvalidError: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }

    lastNameChange(e) {
        this.setState({
            lastName: e,
            firstNameError: '',
            firstNameValid: false,
            lastNameError: '',
            lastnameValid: false,
            emailError: '',
            emailvalidError: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }
    onEmailChange(e) {
        this.setState({
            emailvalidError: false,
            email: e.target.value,
            responseStatus: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }
    onChangePassword(e) {
        this.setState({
            password: e.target.value,
            passwordValidError: false,
            responseStatus: false,
            responseStatus1: false,
            resposneStatusText1: ''
        })
    }

    companyNameChange = (val) => {
        var regex = /^[A-Za-z0-9 ]+$/

        //Validate company name value against the Regex.
        if (val !== '' && !regex.test(val)) {
            this.setState({
                companyNameError: `Special characters not allowed in company name`,
                companyNameValidError: true
            })
        } else {
            this.setState({
                company_name: val,
                companyNameError: "",
                companyNameValidError: false,
                responseStatus: false,
                responseStatus1: false,
                resposneStatusText1: ''

            })
        }
    }

    componentWillMount() {
        this.timer = null;
        this.timer1 = null;
    }

    onChangeJobtitle = (val) => {
        this.setState({
            jobSelected: true
        })
        clearTimeout(this.timer);
        this.setState({
            search: val,
            jobtitle: ''
        })
        this.timer1 = setTimeout(
            this.delaySearch, 800);
    }

    delaySearch = () => {
        const filtered = this.state.jobs.filter(el => {
            if (this.state.search === '') {
                return
            }
            else if (el.title.toLowerCase().includes(this.state.search.toLowerCase())) {
                return el
            }
        })
        if (filtered?.length) {
            this.setState({
                searchJobs: filtered,
            })
        }
        else {
            let skillEmptyList = [];
            skillEmptyList.push({
                title: this.state.search,
                flag: true,
            });
            this.setState({
                searchJobs: skillEmptyList,
            })
        }
        this.setState({
            jobSelected: false,
            jobtitleError: '',
            jobtitleValidError: false
        })
    }

    selectedJob = (job) => {
        console.log(job)
        this.setState({
            search: job.title,
            jobtitle: job._id,
            searchJobs: [],
            jobtitleError: '',
            jobtitleValidError: false
        })
    }

    loginUser(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        let emailvalue = this.state.email;
        const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.firstName) {
            this.setState({
                firstNameError: "Please enter the first name",
                firstNameValid: true,
                isLoading: false
            })
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.firstNameError,
                'Error URL': window.location.href
            });
            return false;
        }

        else if (!this.state.lastName) {
            this.setState({
                lastNameError: 'Please enter the last name',
                lastnameValid: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.lastNameError,
                'Error URL': window.location.href
            });
            return false;
        }

        else if (!emailvalue) {
            this.setState({
                emailError: 'Email cant be blank',
                emailvalidError: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.emailError,
                'Error URL': window.location.href
            });
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            this.setState({
                emailError: "Email is not valid",
                emailvalidError: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.emailError,
                'Error URL': window.location.href
            });
            return false;
        }
        else if (!validemail.test(String(emailvalue.toLowerCase()))) {
            this.setState({
                emailError: "Please enter only work email address",
                emailvalidError: true,
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.emailError,
                'Error URL': window.location.href
            });
            return false;
        }
        else if (this.state.password === "") {
            this.setState({
                passwordValidError: true,
                passworderror: 'Please enter password',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.passworderror,
                'Error URL': window.location.href
            });
        }
        else if (this.state.company_name === "") {
            this.setState({
                companyNameValidError: true,
                companyNameError: 'Please enter Company name',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': 'Please enter Company name',
                'Error URL': window.location.href
            });
        }

        else if (this.state.search === '') {
            this.setState({
                jobtitleValidError: true,
                jobtitleError: 'Please Select from the suggested list of Job titles only',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.jobtitleError,
                'Error URL': window.location.href
            });
        }
        else if (this.state.jobtitle === "") {
            this.setState({
                jobtitleValidError: true,
                jobtitleError: 'Please Select from the suggested list of Job titles only',
                isLoading: false
            });
            window.dataLayer.push({
                'event': 'errorMessage',
                'Error Message': this.state.jobtitleError,
                'Error URL': window.location.href
            });
        }

        else {
            var obj
            if (this.state.jobtitle) {
                obj = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    password: this.state.password,
                    company_name: this.state.company_name,
                    job_id: this.state.jobtitle
                }
            }
            else {
                obj = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    password: this.state.password,
                    company_name: this.state.company_name,
                    job: this.state.search
                }
            }
            console.log(obj)
            axios.post(process.env.REACT_APP_API_KEY + 'enterprise/onboard/company', obj).then((response) => {
                console.log(response.data.url)
                if (response.data.success) {
                    window.location.href = response.data.url
                }
                else {
                    this.setState({
                        responseStatus1: true,
                        isLoading: false,
                        resposneStatusText1: response.data.message,
                        show1: true
                    })

                    // toast.error(this.state.resposneStatusText1, { position: 'top-right', autoClose: 5000, })
                }
            })
                .catch(function (error, response) {
                    console.log(error, response);
                })
        }
    }

    loginOnKeyPress(e) {
        if (e.which === 13 || e.keyCode === 13) {
            this.setState({
                isLoading: true
            })
            let emailvalue = this.state.email;
            const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!this.state.firstName) {
                this.setState({
                    firstNameError: "Please enter the first name",
                    firstNameValid: true,
                    isLoading: false
                })
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.firstNameError,
                    'Error URL': window.location.href
                });
                return false;
            }

            else if (!this.state.lastName) {
                this.setState({
                    lastNameError: 'Please enter the last name',
                    lastnameValid: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.lastNameError,
                    'Error URL': window.location.href
                });
                return false;
            }

            else if (!emailvalue) {
                this.setState({
                    emailError: 'Email cant be blank',
                    emailvalidError: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.emailError,
                    'Error URL': window.location.href
                });
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                this.setState({
                    // emailvalidError: 'Email is not valid',
                    emailError: "Email is not valid",
                    emailvalidError: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.emailError,
                    'Error URL': window.location.href
                });
                return false;
            }
            else if (!validemail.test(String(emailvalue.toLowerCase()))) {
                this.setState({
                    emailError: "Please enter only work email address",
                    emailvalidError: true,
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.emailError,
                    'Error URL': window.location.href
                });
                return false;
            }
            else if (this.state.password === "") {
                this.setState({
                    passwordValidError: true,
                    passworderror: 'Please enter password',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.passworderror,
                    'Error URL': window.location.href
                });
            }
            if (this.state.company_name === "") {
                this.setState({
                    companyNameValidError: true,
                    companyNameError: 'Please enter Company name',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': 'Please enter Company name',
                    'Error URL': window.location.href
                });
            }
            else if (this.state.search === '') {
                this.setState({
                    jobtitleValidError: true,
                    jobtitleError: 'Please Select from the suggested list of Job titles only',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.jobtitleError,
                    'Error URL': window.location.href
                });
            }
            else if (this.state.jobtitle === "") {
                this.setState({
                    jobtitleValidError: true,
                    jobtitleError: 'Please Select from the suggested list of Job titles only',
                    isLoading: false
                });
                window.dataLayer.push({
                    'event': 'errorMessage',
                    'Error Message': this.state.jobtitleError,
                    'Error URL': window.location.href
                });
            }
            else {
                var obj
                if (this.state.jobtitle) {
                    obj = {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        password: this.state.password,
                        company_name: this.state.company_name,
                        job_id: this.state.jobtitle
                    }
                }
                else {
                    obj = {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        password: this.state.password,
                        company_name: this.state.company_name,
                        job: this.state.search
                    }
                }

                axios.post(process.env.REACT_APP_API_KEY + 'enterprise/onboard/company', obj).then((response) => {
                    if (response.data.success) {
                        window.location.href = response.data.url
                    }
                    else {
                        this.setState({
                            responseStatus1: true,
                            isLoading: false,
                            resposneStatusText1: response.data.message,
                            show1: true
                        })
                    }
                })
                    .catch(function (error, response) {
                        console.log(error, response);
                    })

            }
        }
    }

    onfocus = (e) => {
        e.preventDefault();
        e.stopPropagation()
        this.setState({
            show: true
        })
        setTimeout(() => { this.setState({ show: false }); }, 2000);
    }



    renderTooltip(props) {
        return <Tooltip {...props}>
            Start entering your job title to select from the suggested list of job titles that matches closely with your current job title or add your own.
        </Tooltip>;
    }


    renderNothing(props) {
        return <div />
    }

    setClose = () => {
        this.setState({
            show1: false
        })
    }

    checkEmail = (email) => {
        axios.get(process.env.REACT_APP_API_KEY + `enterprise/external/check?email=${email}`).then((response) => {
            console.log(response.data)
            if (response.data.success) {
                this.setState({
                    verified: response.data.verified
                })
            }
            else {
                this.setState({
                    verifyMessage: response.data.message,
                    responseStatus1: true,
                    show1: true,
                    resposneStatusText1: response.data.message
                })
            }
        })
            .catch(function (error, response) {
                console.log(error, response);
            })
    }

    onCheckEmail = (val) => {
        clearTimeout(this.timer1);
        this.setState({
            email: val,
            emailError: '',
            emailvalidError: false,
            verifyMessage: ''
        })
        this.timer1 = setTimeout(
            this.delaySearch1, 800);
    }

    delaySearch1 = async () => {
        this.state.email !== '' && await this.checkEmail(this.state.email);
        if ((this.state.verified === true) || (this.state.verified === false)) {
            let emailvalue = this.state.email
            const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailvalue) {
                this.setState({
                    emailError: 'Email cant be blank',
                    emailvalidError: true,
                    isLoading: false
                });
            }
            else if (!emailrex.test(String(emailvalue))) {
                this.setState({
                    emailError: "Email is not valid",
                    emailvalidError: true,
                    isLoading: false
                });
            }
            else if (!validemail.test(String(emailvalue.toLowerCase()))) {
                this.setState({
                    emailError: "Please enter only work email address",
                    emailvalidError: true,
                    isLoading: false
                });
            }
        }
    }

    verifyUser = (e) => {
        e.preventDefault();
        console.log('ffff')
        this.setState({
            isLoading1: true
        })
        let emailvalue = this.state.email;
        const validemail = /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.firstName) {
            this.setState({
                firstNameError: "Please enter the first name",
                firstNameValid: true,
                isLoading1: false
            })
        }

        else if (!this.state.lastName) {
            this.setState({
                lastNameError: 'Please enter the last name',
                lastnameValid: true,
                isLoading1: false
            });
        }

        else if (!emailvalue) {
            this.setState({
                emailError: 'Email cant be blank',
                emailvalidError: true,
                isLoading1: false
            });
        }
        else if (!emailrex.test(String(emailvalue))) {
            this.setState({
                emailError: "Email is not valid",
                emailvalidError: true,
                isLoading1: false
            });
        }
        else if (!validemail.test(String(emailvalue.toLowerCase()))) {
            this.setState({
                emailError: "Please enter only work email address",
                emailvalidError: true,
                isLoading1: false
            });
        } else {
            let obj = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email
            }
            console.log(obj)
            axios.post(process.env.REACT_APP_API_KEY + 'enterprise/external/generate-link', obj).then((response) => {
                console.log(response.data)
                if (response.data.success) {

                    this.setState({
                        verify: true,
                        isLoading1: false,
                        // resposneStatusText1: 'Please check your email and verify',
                        // show1: true,
                        firstName: '',
                        lastName: '',
                        email: ''
                    }, () => {

                        // window.location.href = `http://localhost:3000/register-demo?_id=${response.data.link.split('/')[6]}`
                    })
                }
                else {
                    this.setState({
                        responseStatus1: true,
                        isLoading1: false,
                        resposneStatusText1: response.data.message,
                        show1: true
                    })

                    // toast.error(this.state.resposneStatusText1, { position: 'top-right', autoClose: 5000, })
                }
            })
                .catch(function (error, response) {
                    console.log(error, response);
                })
        }
    }

    render() {
        const { searchJobs, defaultScreen } = this.state
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: EmailSent,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        return (
            <Fragment>
                <ToastNotification
                    message={this.state.resposneStatusText1}
                    show={this.state.show1}
                    setClose={this.setClose}
                />
                <div className='admin-signup-holder' style={{ height: '100vh', backgroundImage: `url(${this.state.imgSelected?.image})` }}>
                    {
                        defaultScreen ?
                            <div className='login-holder'>
                                <div className='login-box'>
                                    <img src='/images/elite-big-logo.png' width="158" height="68" alt='' />
                                    <p className='text-center'>Create an Elite Enterprise account and start your team's learning journey!</p>
                                    <div className='small'>All (*) marked fields are mandatory</div>
                                    <form name='register-form'>

                                        <div className="form-group mt-2" >
                                            {
                                                this.state.firstNameValid ? <span className='err'>{this.state.firstNameError}</span> : null
                                            }
                                            <div className="has-search"  >
                                                <span className="form-control-feedback" >
                                                    <img src="/images/name-icon.svg" alt='' />
                                                </span>
                                                <input type="text" name='first name' disabled className="form-control" id="first-name" placeholder="First Name*" value={this.state.firstName} onChange={(e) => this.firstNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.lastnameValid ? <span className='err'>{this.state.lastNameError}</span> : null
                                            }
                                            <div className="has-search"  >
                                                <span className="form-control-feedback" >
                                                    <img src="/images/name-icon.svg" alt='' />
                                                </span>
                                                <input type="text" name='last name' disabled className="form-control" id="last-name" placeholder={"Last Name*"} value={this.state.lastName} onChange={(e) => this.lastNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.emailvalidError ? <span className='err'>{this.state.emailError}</span> : null
                                            }
                                            <div className="has-search"  >
                                                <span className="form-control-feedback" >
                                                    <img src="/images/email-icon.svg" alt='' />
                                                </span>
                                                <input type="email" name='email' disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter only work email*" value={this.state.email} onChange={(e) => this.onEmailChange(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.passwordValidError ? <span className='err'>{this.state.passworderror}</span> : null
                                            }

                                            <div className="has-search"  >
                                                <span className="form-control-feedback" >
                                                    <img src="/images/lock-icon.svg" alt='' />
                                                </span>
                                                <input type="password" name='password' className="form-control" aria-describedby="emailHelp" placeholder="Password*" onChange={(e) => this.onChangePassword(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            {
                                                this.state.companyNameValidError ? <span className='err'>{this.state.companyNameError}</span> : null
                                            }
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/company-icon.svg" alt='' />
                                                </span>
                                                <input type="text" className="form-control" value={this.state.company_name} name='company name' placeholder="Company Name*" onChange={(e) => this.companyNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group position-relative">
                                            {
                                                this.state.jobtitleValidError ? <span className='err'>{this.state.jobtitleError}</span> : null
                                            }
                                            <div className="icon-selected">
                                                {this.state.jobSelected ? (
                                                    <BallClipRotate
                                                        color={"#000"}
                                                        loading={true}
                                                    />
                                                ) : null}
                                            </div>
                                            <OverlayTrigger
                                                show={this.state.show}
                                                // trigger='click'
                                                placement={'right'}
                                                arrowProps
                                                onHide={() => this.setState({ show: false })}
                                                rootClose
                                                overlay={
                                                    this.state.show ?
                                                        this.renderTooltip :
                                                        this.renderNothing
                                                }
                                            >
                                                <input type="text" className="form-control" autoComplete='off' name='job-title' value={this.state.search} aria-describedby="Help" placeholder="Enter your Job title*" onChange={(e) => this.onChangeJobtitle(e.target.value)} onClick={(e) => this.onfocus(e)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                            </OverlayTrigger>
                                            {
                                                searchJobs !== undefined &&
                                                    searchJobs.length > 0 &&
                                                    this.state.search !== "" ? (
                                                    <div className="list-container">
                                                        <ul>
                                                            {
                                                                searchJobs !== undefined &&
                                                                    searchJobs.length > 0
                                                                    ? searchJobs.map((items, i) => {
                                                                        return (
                                                                            <li
                                                                                onClick={() =>
                                                                                    this.selectedJob(items)
                                                                                }
                                                                            >
                                                                                {items.flag !== undefined ? (
                                                                                    <i className="fa fa-plus-circle"></i>
                                                                                ) : null}
                                                                                {items.title}
                                                                            </li>
                                                                        );
                                                                    })
                                                                    : null
                                                            }
                                                        </ul>
                                                    </div>
                                                ) :
                                                    null
                                            }
                                        </div>
                                        <div className='form-group mb-0' >
                                            <button type='submit' className='form-control button-color' onClick={(e) => this.loginUser(e)} style={{ pointerEvents: this.state.isLoading ? 'none' : 'auto', borderRadius: 2, fontWeight: 500 }} >
                                                {this.state.isLoading ? <BallBeat
                                                    color={'#fff'}
                                                    loading={true}
                                                /> : "SUBMIT"
                                                }</button>
                                        </div>
                                        <p className='mt-2 cursor-pointer'>Registered user?
                                            <a href='https://app.elitelearning.io/user-login' target='_blank' style={{ textDecoration: 'none', color: '#8b7df2' }} rel="noreferrer" > Login here</a></p>
                                    </form>
                                    <p className="mt-2">Download the Elite mobile app</p>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.app.elitelearning112"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                alt=""
                                                src="/images/app-link.jpg"
                                                width="120px"
                                            />
                                        </a>
                                        <a
                                            href="https://apps.apple.com/in/app/elite-for-workplace/id1543459250"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                alt=""
                                                src="/images/ios-link1.png"
                                                width="120px"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='login-holder'>
                                <div className='login-box'>
                                    <img src='/images/elite-big-logo.png' width="158" height="68" alt='' />
                                    {
                                        !this.state.verify ?
                                            <Fragment>
                                                <p className='text-center'>Create an Elite Enterprise account and start your team's learning journey!</p>
                                                <div className='small'>All (*) marked fields are mandatory</div>
                                                <form name='verify-form'>

                                                    <div className="form-group" >
                                                        {
                                                            this.state.firstNameValid ? <span className='err'>{this.state.firstNameError}</span> : null
                                                        }
                                                        <div className="has-search"  >
                                                            <span className="form-control-feedback" >
                                                                <img src="/images/name-icon.svg" alt='' />
                                                            </span>
                                                            <input type="text" name='first name' className="form-control" id="first-name" placeholder="First Name*" value={this.state.firstName} onChange={(e) => this.firstNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        {
                                                            this.state.lastnameValid ? <span className='err'>{this.state.lastNameError}</span> : null
                                                        }
                                                        <div className="has-search"  >
                                                            <span className="form-control-feedback" >
                                                                <img src="/images/name-icon.svg" alt='' />
                                                            </span>
                                                            <input type="text" name='last name' className="form-control" id="last-name" placeholder={"Last Name*"} value={this.state.lastName} onChange={(e) => this.lastNameChange(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        {
                                                            this.state.emailvalidError ? <span className='err'>{this.state.emailError}</span> : null
                                                        }
                                                        <div className="has-search"  >
                                                            <span className="form-control-feedback" >
                                                                <img src="/images/email-icon.svg" alt='' />
                                                            </span>
                                                            <input type="email" name='email' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter only work email*" value={this.state.email} onChange={(e) => this.onCheckEmail(e.target.value)} onKeyPress={(e) => this.loginOnKeyPress(e)} />
                                                            <span className="form-control-feedback1" >
                                                                {this.state.verified ?
                                                                    <img src="/images/tick1.png" width="30" height="30" alt='' />
                                                                    :
                                                                    ''
                                                                }
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className='form-group mb-0' >
                                                        <button type='submit' className='form-control button-color' onClick={(e) => this.verifyUser(e)} style={{ pointerEvents: ((this.state.verifyMessage === 'Email is already in use') || this.state.isLoading1) ? 'none' : 'auto', opacity: ((this.state.verifyMessage === 'Email is already in use') || this.state.isLoading1) ? '0.3' : '1', borderRadius: 2, fontWeight: 500 }} >
                                                            {this.state.isLoading1 ? <BallBeat
                                                                color={'#fff'}
                                                                loading={true}
                                                            /> : "Submit"}</button>
                                                    </div>
                                                    <p className='mt-2 cursor-pointer'>Registered user?
                                                        <a href='https://app.elitelearning.io/user-login' target='_blank' style={{ textDecoration: 'none', color: '#8b7df2' }} rel="noreferrer" > Login here</a></p>
                                                </form>
                                                <p className="mt-2">Download the Elite mobile app</p>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=com.app.elitelearning112"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/images/app-link.jpg"
                                                            width="120px"
                                                        />
                                                    </a>
                                                    <a
                                                        href="https://apps.apple.com/in/app/elite-for-workplace/id1543459250"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/images/ios-link1.png"
                                                            width="120px"
                                                        />
                                                    </a>
                                                </div>
                                            </Fragment> :
                                            <Fragment>
                                                <p className='text-center'>Thank you for signing up! </p>
                                                <Lottie options={defaultOptions} width='250px' height='250px' />
                                                <p className='text-center'>You have taken the first step of your upskilling journey. <br/><br/>
                                                    Please check your email to activate your account.</p>
                                            </Fragment>
                                    }

                                </div>
                            </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default AdminSignup;

