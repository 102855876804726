import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminSignup from './Components/Register/register';
import AdminSignupV2 from './Components/Register/register-v2';
import AdminSignupWithRedeem from './Components/Redeem/redeem';

function App() {
  React.useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.href = '/register'
    }
  }, [])


  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/register' element={<AdminSignup />} />
          <Route path='/register-demo' element={<AdminSignupV2 />} />
          <Route path='/coupon-redeem' element={<AdminSignupWithRedeem />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
