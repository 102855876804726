import './loader.scss'


export const BallBeat = (props) => {
    return (
        <div class="ball-loader" >
            <div class="ball-loader-ball ball1" style={{ backgroundColor: props.color }}></div>
            <div class="ball-loader-ball ball2" style={{ backgroundColor: props.color }}></div>
            <div class="ball-loader-ball ball3" style={{ backgroundColor: props.color }}></div>
        </div>
    )
}

export const BallClipRotate = () => {
    return (
        <div class="la-ball-clip-rotate la-dark">
            <div></div>
        </div>)
}